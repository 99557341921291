import { produce } from 'immer';
import {
	SET_FILTERED_COLUMNS,
	SET_FILTERS,
	SET_PAGE,
	SET_PAGE_SIZE,
	SET_QUICK_FILTERS,
	SET_SORTED_COLUMNS,
	SET_TOGGLED_COLUMNS
} from './actions';

// The initial state
export const initialState = {
	filters: { number: [], mrpController: [], supplier: [], 'firstOrder.orderDate': [] },
	page: 0,
	pageSize: 20,
	quickFilters: [],
	sortedColumns: [{ id: 'firstOrder.orderDate', desc: false }],
	toggledColumns: []
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
	produce(state, draft => {
		switch (action.type) {

			case SET_FILTERS:
				draft.filters = action.payload;
				break;

			case SET_QUICK_FILTERS:
				draft.quickFilters = action.payload;
				break;

			case SET_SORTED_COLUMNS:
				draft.sortedColumns = action.payload;
				break;

			case SET_TOGGLED_COLUMNS:
				draft.toggledColumns = action.payload;
				break;

			case SET_PAGE_SIZE:
				draft.pageSize = action.payload;
				break;

			case SET_PAGE:
				draft.page = action.payload;
				break;

			case SET_FILTERED_COLUMNS:
				draft.filteredColumns = action.payload;
				break;

		}
	});

export default reducer;
